





































































































































import BaseImage from '@/components/base/BaseImage.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import ImageChangeable from '@/components/ImageChangeable.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import Vue, { VueConstructor } from 'vue';
import VueBarcode from '@xkeshi/vue-barcode';
import { isArrayLikeObject as _isArrayLikeObject } from 'lodash';
import { directiveDroppable, UPLOAD_TYPE } from '@/directives/droppable';
import { entityTypeList } from '@/utils/entity';
import { MAccountNumber } from '@/models/MAccountNumber';
import { MCategory } from '@/models/MCategory';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinSync } from '@/components/mixins/sync';
import { MProduct } from '@/models/MProduct';
import { MTax } from '@/models/MTax';
import { utIsEmpty } from '@/utils/empty';

// Note to future self: MProduct is an ORM model. The Products attributes, however, aren't ORM-ified.
// The general basket is still being pulled out of the store the old way

export default (Vue as VueConstructor).extend({
  name: 'ProductEdit',

  directives: {
    droppable: directiveDroppable,
  },

  components: {
    BaseImage,
    BaseInputField,
    BaseSelect,
    BaseSideLabel,
    ImageChangeable,
    LayoutModalEdit,
    LayoutHeader,
    VueBarcode,
  },

  mixins: [
    mixinAlert,
    mixinModal,
    mixinModes,
    mixinSync,
  ],

  props: {
    ID: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      imageIsLoading: false,
      uploadMode: UPLOAD_TYPE.SINGLE,
    };
  },

  computed: {
    accountNumberList() {
      return MAccountNumber.all().map(
        accountNumber => accountNumber.asJson,
      );
    },

    categoryList() {
      return MCategory.all();
    },

    currentProduct: {
      get(): MProduct {
        return MProduct.find(this.ID);
      },

      async set(product: MProduct) {
        this.dirty = true;

        await MProduct.update({
          where: this.ID,

          data: product,
        });
      },
    },

    currentAccountNumber: {
      get(): Object {
        return {
          id: this.currentProduct?.AccountNumberId,
          name: MAccountNumber.find(this.currentProduct?.AccountNumberId)?.Name,
        };
      },

      set(value): void {
        this.currentProduct = {
          ...this.currentProduct,
          AccountNumberId: value,
        };
      },
    },

    currentCategory() {
      return this.categoryList.find(
        category => category.Id === this.currentProduct.CategoryId,
      );
    },

    currentProductImageIsPresent(): boolean {
      return !utIsEmpty(this.currentProduct.ImageUrl);
    },

    currentUnit() {
      return this.units.find(
        unit => unit.type === this.currentProduct.Entity,
      );
    },

    currentTax() {
      return MTax.find(this.currentProduct.TaxId);
    },

    productImageUrl(): string {
      if (this.currentProduct) {
        return this.currentProduct?.productImageURL();
      }

      return '';
    },

    units() {
      return entityTypeList;
    },

    taxList() {
      return MTax.all();
    },
  },

  methods: {
    updateProductName(Name: string) {
      this.currentProduct = {
        ...this.currentProduct,
        Name: Name,
      };
    },

    closeModal(): void {
      this.$router.push(this.routeBack);
    },

    async remove(): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$t('product.alert.delete', {
          productName: this.currentProduct.Name,
        }),
      );

      if (confirmation.value) {
        MProduct._delete(this.currentProduct.Id);

        this.closeModal();
      }
    },

    sync(): void {
      MProduct._update(this.currentProduct);
    },

    updateProduct(prop, value): void {
      if (this.currentProduct[prop] !== value) {
        this.currentProduct = {
          ...this.currentProduct,
          [`${prop}`]: value,
        };
      }
    },

    async removeProductImage(): Promise<any> {
      this.imageIsLoading = true;

      await MProduct._deleteImage(this.currentProduct.Id);

      this.imageIsLoading = false;
    },

    async uploadProductImage(fileList: File): Promise<any> {
      this.imageIsLoading = true;

      let file = fileList;

      if (_isArrayLikeObject(fileList)) {
        file = fileList[0];
      }

      await MProduct._uploadImage(file, this.currentProduct.Id);

      this.imageIsLoading = false;
    },
  },

  beforeMount() {
    if (this.currentProduct?.EAN === '' || this.currentProduct?.EAN === null) {
      this.currentProduct = {
        ...this.currentProduct,
        EAN: this.currentProduct.Id.replace('-', '').substr(0, 12),
      };
    }
  },
});
