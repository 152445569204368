





























































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseGridList from '@/components/base/grid/BaseGridList.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import ProductGrid from '@/components/ProductGrid.vue';
import Vue, { VueConstructor } from 'vue';
import { DISPLAY_TYPE, displayTypeList, displayTypeMap } from '@/utils/entity';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { MOrganization } from '@/models/MOrganization';
import { MProduct } from '@/models/MProduct';
import { MSettings } from '@/models/MSettings';
import {
  productRouteEdit,
  productRouteNew,
  productRouteShow,
} from '@/views/definitions/product/routes';
import { productsGridActionList } from '@/utils/gridActions/productsGridActionList';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'ProductsShow',

  components: {
    BaseButtonText,
    BaseGridList,
    BaseSelect,
    LayoutHeader,
    LayoutPage,
    ProductGrid,
  },

  mixins: [
    mixinAlert,
    mixinModal,
  ],

  data() {
    return {
      componentKey: 0,

      displayTypeList,

      gridActionList: productsGridActionList,

      gridColumnDefinition: [
        {
          headerName: this.$tc('product.property.name'),
          field: 'name',
        },
        {
          headerName: this.$tc('product.property.price'),
          field: 'price',
        },
        {
          headerName: this.$tc('product.property.unit'),
          field: 'unit',
        },
      ],
    };
  },

  computed: {
    settings: {
      get(): MSettings {
        return MSettings
          .find(MOrganization.query().first()?.SettingsId);
      },

      set(value): void {
        MSettings.update({
          where: this.settings.Id,
          data: {
            ProductList: value,
          },
        });
      },
    },

    showGrid(): boolean {
      return this.settings?.ProductList === DISPLAY_TYPE.GRID;
    },

    productDisplayedType(): Object {
      return displayTypeMap.get(this.settings?.ProductList);
    },

    productList(): Array<MProduct | any> {
      if (this.showGrid) {
        let productList = [];

        productList = MProduct.query().where((product) => {
          return product.ArticleNumber.includes('GZ');
        }).get().sort((a, b) => {
          const numbersA = Number(a.ArticleNumber.substr(2));
          const numbersB = Number(b.ArticleNumber.substr(2));

          const lettersA = a.ArticleNumber.substr(0, 2);
          const lettersB = b.ArticleNumber.substr(0, 2);

          if (lettersA === lettersB) {
            if (numbersA > numbersB) {
              return 1;
            }
          }

          if (lettersA > lettersB) {
            return 1;
          }

          if (lettersA < lettersB) {
            return -1;
          }

          return -1;
        });

        return productList.concat(MProduct.all().sort((a, b) => {
          const numbersA = Number(a.ArticleNumber.substr(2));
          const numbersB = Number(b.ArticleNumber.substr(2));

          const lettersA = a.ArticleNumber.substr(0, 2);
          const lettersB = b.ArticleNumber.substr(0, 2);

          if (lettersA === lettersB) {
            if (numbersA > numbersB) {
              return 1;
            }
          }

          if (lettersA > lettersB) {
            return 1;
          }

          if (lettersA < lettersB) {
            return -1;
          }

          return -1;
        }));
      }

      return MProduct.all().map(
        product => product.asListData,
      );
    },
  },

  watch: {
    productList: {
      deep: true,
      handler() {
        this.componentKey++;
      },
    },
  },

  methods: {
    add(): void {
      this.$router.push(productRouteNew(this.$i18n.locale));
    },

    closeModal(): void {
      this.$router.push(productRouteShow(this.$i18n.locale));
    },

    edit(productID: string): void {
      this.$router.push({
        name: productRouteEdit(this.$i18n.locale).name,

        params: {
          ID: productID,
        },
      });
    },

    async remove(productID: string): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$tc('product.alert.delete', {
          productName: MProduct.find(productID).Name,
        }),
      );

      if (confirmation.value) {
        MProduct._delete(productID);
      }
    },
  },
});
